

import { ReactComponent as pfLogo } from '../assets/images/pf-at-logo-wide.svg';
import { ReactComponent as iconCart } from '../assets/images/icon-cart.svg';
import { ReactComponent as ornamentBlue } from '../assets/images/ornament-blue.svg';
import { ReactComponent as ornamentRed } from '../assets/images/ornament-red.svg';
import { ReactComponent as ornamentRedShort } from '../assets/images/ornament-red-short.svg';
import { ReactComponent as atGirl } from '../assets/images/at-girl.svg';
import { ReactComponent as atGirl2 } from '../assets/images/at-girl2.svg';
import { ReactComponent as atBoy } from '../assets/images/at-boy.svg';
import presentBoy from '../assets/images/presentBoy.jpg'
import presentGirl from '../assets/images/presentGirl.jpg'
import presentGirl2 from '../assets/images/presentGirl2.jpg'
import accreditedCharitySeal from '../assets/images/financial/accredited-charity-seal.png'
import cfcSeal from '../assets/images/financial/cfc-seal.png'
import ecfaAccreditedSeal from '../assets/images/financial/ecfa-accredited-seal.png'
import guidestarPlatinumSeal from '../assets/images/financial/guidestar-platinum-seal-of-transparency.png'
import secureDonation from '../assets/images/secure_donation.jpg'
import bankButton from '../assets/images/bank-button-image.png'
import creditButton from '../assets/images/credit-button-image.png'

import { ReactComponent as pfCheckboxChecked } from '../assets/images/pf-checkbox-checked.svg';
import { ReactComponent as pfCheckboxUnchecked } from '../assets/images/pf-checkbox-unchecked.svg';

import { ReactComponent as iconCheckCircle } from '../assets/images/icon-check-circle.svg';
import { ReactComponent as iconUncheckCircle } from '../assets/images/icon-uncheck-circle.svg';
import { ReactComponent as gift1 } from '../assets/images/gifts/gift1.svg';
import { ReactComponent as gift2 } from '../assets/images/gifts/gift2.svg';
import { ReactComponent as gift3 } from '../assets/images/gifts/gift3.svg';

export const Gift1 = gift1;
export const Gift2 = gift2;
export const Gift3 = gift3;

export const AngelTreeLogo = pfLogo

export const IconCart = iconCart;
export const OrnamentBlue = ornamentBlue;
export const OrnamentRed = ornamentRed;
export const OrnamentRedShort = ornamentRedShort;
export const PfCheckboxChecked = pfCheckboxChecked;
export const PfCheckboxUnchecked = pfCheckboxUnchecked;
export const IconCheckedCircle = iconCheckCircle;
export const IconUncheckedCircle = iconUncheckCircle;
export const AccreditedCharitySeal = accreditedCharitySeal
export const CfcSeal = cfcSeal
export const EcfaAccreditedSeal = ecfaAccreditedSeal
export const GuidestarPlatinumSeal = guidestarPlatinumSeal
export const SecureDonation = secureDonation
export const BankButton = bankButton
export const CreditButton = creditButton
export const DesktopPaymentScreenImage = presentGirl2;

export const SUB_HEADER_IMAGES = [atBoy, atGirl, atGirl2];
export const PAYMENT_HEADER_IMAGES = [presentBoy, presentGirl, presentGirl2]
