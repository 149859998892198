import React from 'react';

import { ValueType } from 'react-select';
import { ISelectObj } from '../../types';
import { AgeSlider, GenderFilter } from '..';
import { DEFAULT_ORG_FILTERS, layoutBreakpoints } from '../../constants';
import { useSelector } from 'react-redux';
import { getChildrenReduxState } from '../../pages/children';
import { getRefDataState } from '../../selectors/refdata.selector';
import { getAuthReduxState } from '../../pages/auth';
import { useWindowDimensions } from '../../utils';

export interface IChildFilterProps {
  filters: typeof DEFAULT_ORG_FILTERS;
  onChange: (
    value: boolean | string | ValueType<ISelectObj, false> | readonly number[],
    key: keyof typeof DEFAULT_ORG_FILTERS
  ) => void;
}

const ChildFilter: React.FC<IChildFilterProps> = ({
  filters = DEFAULT_ORG_FILTERS,
  onChange,
}) => {
  const { genders } = useSelector(getRefDataState);
  const { numDonatedGifts, numRequestedGifts } = useSelector(
    getChildrenReduxState
  );
  const {
    orgInfo: { isNationalOrg, isPfGeneratedVat },
  } = useSelector(getAuthReduxState);

  const { width } = useWindowDimensions();
  const isMobileLayout = width < layoutBreakpoints.L;

  const applyBoyFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => onChange(checked, 'isBoy');

  const applyGirlFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => onChange(checked, 'isGirl');

  const applyAgeRangeFilter = (event, values) => onChange(values, 'ageRange');

  const renderAgeFilter = () => {
    return (
      <div className="search-children-age-filter">
        <div className="search-label">
          Search by age: {filters.ageRange.at(0)}-{filters.ageRange.at(1)}
        </div>
        <AgeSlider
          onChangeCommitted={applyAgeRangeFilter}
          ageRange={filters.ageRange}
        />
      </div>
    );
  };

  const renderGenderFilter = () => {
    return (
      <div className="search-children-gender-filter">
        <div className="search-label">Search by gender</div>
        <GenderFilter
          isBoyChecked={filters.isBoy || false}
          isGirlChecked={filters.isGirl || false}
          handleBoyClick={applyBoyFilter}
          handleGirlClick={applyGirlFilter}
          genders={genders || []}
        />
      </div>
    );
  };
  
  return (
    <>
      {!isNationalOrg && !isPfGeneratedVat && (
        <p className="sponsored-message">
          SPONSORED {numDonatedGifts} OF {numRequestedGifts} COMMITTED
        </p>
      )}
      <div className="filter-container">
        <div className="filter-message">
          <h2 className="filter-message__title">
            Choose an Angel Tree child and bless a family!
          </h2>
          {!isMobileLayout && (
            <p className="filter-message__text">
              Your generosity today will help deliver a gift, the Gospel, and a
              note from their incarcerated parent to an Angel Tree child. Thanks
              to you, they'll remember this Christmas for the rest of their
              lives. Choose one or more children to sponsor below, then click 
              the cart icon to make your donation!
            </p>
          )}
        </div>
        {isMobileLayout && <hr className="divider" />}
        {isMobileLayout && (
          <div className="search-container">
            {renderAgeFilter()}
            {renderGenderFilter()}
          </div>
        )}
        {!isMobileLayout && (
          <>
            {renderAgeFilter()}
            {renderGenderFilter()}
          </>
        )}
      </div>
    </>
  );
};

export default ChildFilter;
